//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import PerfectScrollbar from 'perfect-scrollbar';
//トップページ　横スクロール
import gsap from 'gsap';
import {
  ScrollTrigger,
  ScrollToPlugin,
  CustomEase
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, CustomEase);
let setTimer = false;

export function idxFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    
    //span自動挿入
    const wrapCharSpan = function(str){
      return [...str].map(char => `<span>${char}</span>`).join('');
    }
    let resizecheck = $('#responsibleCheck').width();
    
    //対象の要素を取得する
    //const target = document.querySelector('.mainviewTxtAnime');
    //要素の内容を関数の実行結果で置き換える
    //target.innerHTML = wrapCharSpan(target.textContent);


    let tl = gsap.timeline();
    tl
    .set(".mainview .back", {xPercent: -130,opacity: 1,})
    .set(".mainview #b rect", {xPercent: -130,opacity: 1,})
    .set(".mainview .mainviewTxtAnime", {backgroundPosition:"-130%",})
    .set(".mainview .mainviewTxtAnimeBox", {x:-10,})
    .set(".mainviewTxtAnime span" , {
      backgroundColor:"rgba(208,16,44,0)", 
    })
    .to(".mainview .back", {
        xPercent: 0,
        ease: "Power4.easeInOut",
        duration: .6
    })
    .to(".mainview .mainviewLead", {
        backgroundPosition:"0", 
        ease: "Power4.easeInOut",
        duration: .6,
    }, "<")
    .to(".mainview .back", {
        xPercent: 150,
        ease: "Power4.easeInOut",
        duration: .6,
    }, ">-0.15")
    .to(".mainview .mainviewLead", {
        backgroundPosition:"-100%", 
        ease: "Power4.easeInOut",
        duration: .6,
    }, "<")
    .to(".mainview .mainviewLeadBox", {
      opacity: 0,
      ease: "Circ.easeOut",
      duration: .2,
    },">")
    .to(".mainview .touch", {
        x: 0,
        opacity: 1,
        visibility: 'visible',
        ease: "Power4.easeInOut",
        duration: .6,
    })
    .to(".mainview .reaction", {
      x: 0,
      opacity: 1,
      visibility: 'visible',
      ease: "Power4.easeInOut",
      duration: .6,
      onStart () {
        $('.mainview .touch').addClass('hidden');
      }
    }, "+=.4")
    .to(".mainview .moment", {
      x: 0,
      opacity: 1,
      visibility: 'visible',
      ease: "Power4.easeInOut",
      duration: .6,
      onStart () {
        $('.mainview .reaction').addClass('hidden');
      }
    }, "+=.4")
    .to(".mainview .moment", {
      onStart () {
        $('.mainview .moment').addClass('hidden');
      }
    }, "+=.4")
    .set(".mainview .back", {xPercent: -130,opacity: 1,})
    .to(".mainview .mainviewTxtBox", {
      opacity: 0,
      visibility: 'hidden',
      ease: "Power4.easeInOut",
      duration: .2,
    })
    .to(".mainview .mainviewTit", {
      opacity: 1,
      visibility: 'visible',
      ease: "Power4.easeInOut",
      duration: .4,
    })
    .to(".mainview .mainviewTxtAnimeBox", {
      opacity: 1,
      visibility: 'visible',
      x: 0,
      ease: "Power4.easeInOut",
      duration: .4,
    })
    .to(".mainview .back", {
        xPercent: 0,
        ease: "Power4.easeInOut",
        duration: .6
    })
    .to(".mainview .mainviewTxtAnime", {
        backgroundPosition:"0", 
        ease: "Power4.easeInOut",
        duration: .6
    }, "<")
    .to(".mainview #b rect", {
        xPercent:0,
        visibility: "visible",
        ease: "Power4.easeInOut",
        duration: .6,
    }, "<")
    .to(".mainview .back", {
        xPercent: 120,
        ease: "Power4.easeInOut",
        duration: .6
    }, ">-0.15")
    .to(".mainview .mainviewTxtAnime", {
        backgroundPosition:"-120%", 
        ease: "Power4.easeInOut",
        duration: .6
    }, "<")
    .to(".mainview #b rect", {
        xPercent: 120,
        ease: "Power4.easeInOut",
        duration: .6,

        onComplete (){
          textAnime();
          $('.scroll').addClass('active');
        },
    }, "<");

    function textAnime() {
      let tl = gsap.timeline({ repeat: -1,repeatDelay: 2.5, delay: .5,} );
      tl
      // .set(".mainviewTxtAnime span" , {
      //   width: "random([42, 61, 76])",
      //   backgroundColor:"rgba(208,16,44,0)", 
      // })
      .to(".mainviewTxtAnime span" , {
          opacity: 0, 
          backgroundColor:"rgba(208,16,44,1)", 
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          },
      }, "<")
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(231,46,121,1)", 
          //y: "random([25, 8, 28, 0, 13, 31])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      }, "<")
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(231,46,121,1)", 
          //y: "random([25, 8, 28, 0, 13, 31])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      })
      .to(".mainviewTxtAnime span", {
          opacity: 0, 
          backgroundColor:"rgba(208,16,44,0)", 
          //y: "random([25, 8, 28, 0, 13, 31])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      })
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(208,16,44,0)", 
          //y: 0,
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          },
      });
    }
    function textAnimeSP() {
      let tl = gsap.timeline({ repeat: -1,repeatDelay: 2.5, delay: .5,} );
      tl
      // .set(".mainviewTxtAnime span" , {
      //   width: "random([30, 27, 21])",
      // })
      .to(".mainviewTxtAnime span" , {
          opacity: 0, 
          backgroundColor:"rgba(231,46,121,1)", 
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          },
      }, "<")
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(231,46,121,1)", 
          //y: "random([10, 2, 15, 42, 0, 50])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      }, "<")
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(231,46,121,1)", 
          //y: "random([10, 2, 15, 42, 0, 50])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      })
      .to(".mainviewTxtAnime span", {
          opacity: 0, 
          backgroundColor:"rgba(208,16,44,0)", 
          //y: "random([10, 2, 15, 42, 0, 50])",
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          }
      })
      .to(".mainviewTxtAnime span", {
          opacity: 1, 
          backgroundColor:"rgba(208,16,44,0)", 
          y: 0,
          ease: "Power4.easeInOut",
          duration: .005,
          stagger: {
            from: 'random',
            each: .05
          },
      });
    }

    let toggleBtn = $('#profileMoreBtn');

    toggleBtn.on('click', function () {
      if ($(this).next().not(':animated').length >= 1) {
        $(this).stop().toggleClass('open');
        $(this).stop().next('#profileMoreCon').slideToggle(function (){
          ScrollTrigger.refresh();//アコーディオンで高さが変わった際に、gsapをリフレッシュする
        });
      }
    });

    let portfolioMoreBtn = $('.portfolioMoreBtn');

    portfolioMoreBtn.on('click', function () {
      if ($(this).next().not(':animated').length >= 1) {
        $(this).stop().toggleClass('open');
        $(this).stop().next('.cardTxt').slideToggle(function (){
          ScrollTrigger.refresh();//アコーディオンで高さが変わった際に、gsapをリフレッシュする
        });
      }
    });

    function sliderSetting() {
      var width = window.innerWidth;
      if (width <= 767) {
        $('#portfolioSlideArea').not('.slick-initialized').slick({
          loop: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 400,
          infinite: true,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          centerMode: true,
					prevArrow: '<img src="/images/common/portfolio_arrow_left.svg" class="slide-arrow prev-arrow">',
					nextArrow: '<img src="/images/common/portfolio_arrow_right.svg" class="slide-arrow next-arrow">',
        });
      } else {
        $('#portfolioSlideArea.slick-initialized').slick('unslick');
      }
    }

    // 初期表示時の実行
    sliderSetting();

    // リサイズ時の実行
    var prewidth = $(window).width();
    $(window).on('resize', function () {
      if (setTimer !== false) {
        clearTimeout(setTimer);
      }
      setTimer = setTimeout(() => {
        var nowWidth = $(window).width();
        if(prewidth !== nowWidth){
          sliderSetting();
          amepTxtBoxTlAnimeStart();
        }
        prewidth = nowWidth;
        ScrollTrigger.refresh();
      }, 200);
    });

    $('#sourceSlideArea').slick({
      loop: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 400,
      infinite: true,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      variableWidth: true,
    });

    function playVideos(videoWrappers) {
      const startPosition = window.pageYOffset + window.innerHeight;
      for(let i = 0; i < videoWrappers.length; i++) {
          const videoPosition = videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
          if(startPosition > videoPosition) {
              const video = videoWrappers[i].getElementsByTagName('video');
              video[0].play();
          }
      }
    }
    const videoWrappers = document.getElementsByClassName('videoControl');
    if(videoWrappers.length) {
        playVideos(videoWrappers);
        window.addEventListener('scroll', function() {
            playVideos(videoWrappers);
        }, false);
    }

    var ps = new PerfectScrollbar('.newsList');

    document.querySelectorAll(".commonTit").forEach((el) => {
      ScrollTrigger.create({
        invalidateOnRefresh: true,
        trigger: el,
        start: 'top 75%',
        end: "bottom top",
        onEnter: () => el.classList.add('active'), 
        once: true,
        //markers: true,
      });
    });
    document.querySelectorAll(".commonSubTit").forEach((el) => {
      ScrollTrigger.create({
        invalidateOnRefresh: true,
        trigger: el,
        start: 'top 75%',
        end: "bottom top",
        onEnter: () => el.classList.add('active'), 
        once: true,
        //markers: true,
      });
    });
    document.querySelectorAll(".animationLine").forEach((el) => {
      ScrollTrigger.create({
        invalidateOnRefresh: true,
        trigger: el,
        start: 'top 75%',
        end: "bottom top",
        onEnter: () => el.classList.add('active'), 
        once: true,
        //markers: true,
      });
    });
    // document.querySelectorAll(".ourModelListActiveImg").forEach((el) => {
    //   ScrollTrigger.create({
    //     invalidateOnRefresh: true,
    //     trigger: el,
    //     start: 'top 75%',
    //     end: "bottom top",
    //     onEnter: () => el.classList.add('active'), 
    //     once: true,
    //     stagger: {
    //       from: 'start',
    //       each: .05
    //     },
    //     //markers: true,
    //   });
    // });
    gsap.to(".ourModelListActiveImg", {
      opacity:1,
      ease: "Power4.easeInOut",
      duration: .5,
      stagger: {
        each: .3
      },
      onComplete: () => {
        if (resizecheck == '2') {
          amepTxtBoxTl.play();
        }
      },
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: '.ourModelListActiveImg',
        start: 'top 75%',
        once: true,
        end: "bottom top",
      }
    });

    ScrollTrigger.create({
      trigger: '.ourModelSpBox', //アニメーションが始まるトリガーとなる要素
      start: 'top 75%' ,
      toggleClass: {targets: ".ourModelSpBox", className: "active"}, //クラスをつけたり、外したりできる
      once: true
    });

    // ScrollTrigger.addEventListener("refresh", function() {
    //   console.log('refresh');
    // });

    const custom_anime = gsap.timeline({
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: ".ourModelAnimeArea", //アニメーションが始まるトリガーとなる要素
        start: "top 75%",
        //markers: true,
      },
    });
    custom_anime
    .set(".amepImg", {yPercent: 100,})
    .to(".modelImg .tpBk", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg .tpTxt", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg .arrowRightTop", {
      clipPath: "polygon(0 0, 100% 0, 100% 150%, 0% 100%)",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1,
    }, "<")
    .to(".modelImg .arrowLeftTop", {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1,
    }, "<")
    .to(".amepImg", {
      yPercent: 0,
      opacity: 1,
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1.8,
    }, ">")
    .to(".modelImg .momentBk", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
      onStart () {
        $('.modelImg #momentGroup').addClass('active');
      }
    }, "<")
    .to(".modelImg .momentTxt", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg #reactionBk", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1,
    },"<1")
    .to(".modelImg #reactionTxt", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1,
    }, "<")
    .to(".modelImg .arrowLeftBottom", {
      clipPath: "polygon(0 0, 100% 0, 100% 150%, 0% 100%)",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1.5,
    }, "<")
    .to(".modelImg .arrowRightBottom", {
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: 1.5,
      onComplete () {
        $('.ourModelAnimeArea').addClass('active');
      }
    }, "<")
    .to(".modelImg .tpBk", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<2.4")
    .to(".modelImg .tpTxt", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg .momentBk", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
      onStart () {
        $('.modelImg #momentGroup').addClass('active');
      }
    }, "<")
    .to(".modelImg .momentTxt", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg #reactionBk", {
      fill: "#fff",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      duration: .8,
    }, "<")
    .to(".modelImg #reactionTxt", {
      fill: "#e72e79",
      ease:  CustomEase.create("custom", "M0,0 C0.075,0.82 0.165,1 1,1 "),
      onComplete () {
        $('.amepBox').addClass('amepBoxActive');

        if (resizecheck == '1') {
          amepTxtBoxTl.play();
        }
      },
    }, "<");

    var amepTxtBoxTlAnimeStart = function () {
      amepTxtBoxTl.pause();
    }

    let amepTxtBoxTl = gsap.timeline({ repeat: -1,repeatDelay: 4, delay: .5, paused: true} );
    amepTxtBoxTl
    .to(".amepTxtAttention" , {
      onStart: () => {
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtAttention').addClass('active');
      },
    })
    .to(".amepTxtMecca" , {
      onStart: () => {
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtMecca').addClass('active');
      },
    }, "<4")
    .to(".amepTxtEngagement" , {
      onStart: () => {
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtEngagement').addClass('active');
      },
    }, "<4")  
    .to(".amepTxtPurchase" , {
      onStart: () => {
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtPurchase').addClass('active');
      },
    }, "<4");

    // const attentionBtn = document.querySelectorAll('.amepBoxActive .attentionBtn');
    // const amepTxtAttention = document.querySelectorAll('.amepTxtAttention');
    // attentionBtn.addEventListener('mouseenter', () => {
    //   amepTxtAttention.add.classList = "active";
    // }, false);
    
    $('.ourModelList .list').on('click', function () {
      if (resizecheck == '2') {
        let article = $(this).index();
        
        amepTxtBoxTl.pause();
        $(".amepTxtBoxSp .amepTxtBox").removeClass("active");
        $(".amepTxtBoxSp .amepTxtBox").eq(article).addClass("active");

        setTimeout(function(){
          amepTxtBoxTl.play();
        }, 20000);
      }
    });


    $('.amepTxtBox').on({
      'mouseenter': function () { 
        if (resizecheck == '1') {
          amepTxtBoxTl.pause();
        }
      },
      'mouseleave': function () { 
        if (resizecheck == '1') {
          amepTxtBoxTl.play();
        }
      }
    });

    $('.attentionBtn').on({
      'mouseenter': function () { 
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtAttention').addClass('active');
      }
    });
    $('.amepTxtAttention').on({
      'mouseleave': function () { 
        if (resizecheck == '1') {
          $('.amepTxtAttention').removeClass('active');
        }
      }
    });
    $('.meccaBtn').on({
      'mouseenter': function () { 
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtMecca').addClass('active');
      }
    });
    $('.amepTxtMecca').on({
      'mouseleave': function () { 
        if (resizecheck == '1') {
          $('.amepTxtMecca').removeClass('active');
        }
      }
    });
    $('.engagementBtn').on({
      'mouseenter': function () { 
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtEngagement').addClass('active');
      }
    });
    $('.amepTxtEngagement').on({
      'mouseleave': function () { 
        if (resizecheck == '1') {
          $('.amepTxtEngagement').removeClass('active');
        }
      }
    });
    $('.purchaseBtn').on({
      'mouseenter': function () { 
        $('.amepTxtBox').removeClass('active');
        $('.amepTxtPurchase').addClass('active');
      }
    });
    $('.amepTxtPurchase').on({
      'mouseleave': function () { 
        if (resizecheck == '1') {
          $('.amepTxtPurchase').removeClass('active');
        }
      }
    });

    //目次カレント
    document.querySelectorAll(".navCurrent").forEach((el) => {
      var id = el.href.split('#')[1];
      ScrollTrigger.create({
        invalidateOnRefresh: true,
        trigger: $('#' + id),
        start: 'top center',
        end: "bottom center",
        toggleClass: {targets: el, className: "active"},
      });
    });
  }
}